import * as React from "react"
import {graphql} from "gatsby"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {Col, Container, Row} from "react-bootstrap";
import {StaticImage} from "gatsby-plugin-image";
import PageHeaderBlock from "../../components/PageHeaderBlock";
import LastProjects from "../../components/portfolio/LastProjects";

export default function Bestchef() {
    const { t } = useTranslation();

    return (
        <Layout>
            <Seo title={t('portfolioBestChef')} description={t('portfolioBestChef')} />
            <PageHeaderBlock h1="portfolioBestChef" />
            <Container fluid className="px-flex">
                <Row className="my-3 my-xl-4 my-xxl-5">
                    <Col md={6} xxl={8}>
                        <Row>
                            <Col xxl={6}>
                                <StaticImage
                                    src="../../images/portfolio/bestchef.png"
                                    width={1280}
                                    quality={95}
                                    formats={["auto", "webp", "avif"]}
                                    alt={t('portfolioBestChef')}
                                    className="portfolio__img w-100 mb-3 mb-xxl-4"
                                    placeholder="blurred"
                                />
                            </Col>
                            <Col xxl={6}>
                                <StaticImage
                                    src="../../images/portfolio/bestchef/bestchef-1.png"
                                    width={1600}
                                    quality={95}
                                    formats={["auto", "webp", "avif"]}
                                    alt={t('portfolioBestChef')}
                                    className="portfolio__img w-100 mb-3 mb-xxl-4"
                                    placeholder="blurred"
                                />
                            </Col>
                            <Col xxl={6}>
                                <StaticImage
                                    src="../../images/portfolio/bestchef/bestchef-2.png"
                                    width={1600}
                                    quality={95}
                                    formats={["auto", "webp", "avif"]}
                                    alt={t('portfolioBestChef')}
                                    className="portfolio__img w-100 mb-3 mb-xxl-4"
                                    placeholder="blurred"
                                />
                            </Col>
                            <Col xxl={6}>
                                <StaticImage
                                    src="../../images/portfolio/bestchef/bestchef-3.png"
                                    width={1600}
                                    quality={95}
                                    formats={["auto", "webp", "avif"]}
                                    alt={t('portfolioBestChef')}
                                    className="portfolio__img w-100 mb-3 mb-xxl-4"
                                    placeholder="blurred"
                                />
                            </Col>
                            <Col xxl={6}>
                                <StaticImage
                                    src="../../images/portfolio/bestchef/bestchef-4.png"
                                    width={1600}
                                    quality={95}
                                    formats={["auto", "webp", "avif"]}
                                    alt={t('portfolioBestChef')}
                                    className="portfolio__img w-100 mb-3 mb-xxl-4"
                                    placeholder="blurred"
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={{ span: 5, offset: 1 }} xxl={{ span: 3, offset: 1 }} className="blog-post">
                        <article className="portfolio__text position-sticky">
                            <h2>{t('portfolioDescription')}</h2>
                            <article dangerouslySetInnerHTML={{__html: t('portfolioBestChefText')}} />
                            <h3 className="mt-3 mt-xl-4 pt-xxl-1">{t('portfolioTechnologies')}</h3>
                            <ul dangerouslySetInnerHTML={{__html: t('portfolioBestChefText1')}} />
                        </article>
                    </Col>
                </Row>
                <LastProjects />
            </Container>
        </Layout>
    )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;